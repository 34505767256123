import Moment from "react-moment";
import { Link } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
export default function ListingItem({ listing, id, onEdit, onDelete }) {
  return (
    <li className="relative bg-white flex flex-col justify-between items-center shadow-md hover:shadow-xl rounded-md overflow-hidden transition-shadow duration-150 m-[10px]">
      <Link className="contents" to={`/category/${listing.type}/${id}`}>
        <img
          className="h-[170px] w-full object-cover hover:scale-105 transition-scale duration-200 ease-in"
          loading="lazy"
          src={listing.imgUrls[0]}
        />
        <Moment
          className="absolute top-2 left-2 bg-[#3377cc] text-white uppercase text-xs font-semibold rounded-md px-2 py-1 shadow-lg"
          fromNow
        >
          {listing.timestamp?.toDate()}
        </Moment>

   

        <div className="w-full p-[10px]">


          <div className="flex justify-end  items-center space-x-4 w-[98%]">
            
            <p className="bg-green-800 w-full max-w-[130px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
            {listing.name}
            </p>
          </div>

          
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[80px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"وقف الخسائر"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"الهدف"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"سعر الشراء"}   
              </p>
            </div>
          </div>

          
          
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[80px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.stoploss > 1 ? `${listing.stoploss}  ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.target > 1 ? `${listing.target} ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.buyprice > 1 ? `${listing.buyprice}  ` : ""}  
              </p>
            </div>
          </div>

          
        </div>
      </Link>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500"
          onClick={() => onDelete(listing.id)}
        />
      )}
      {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-4 cursor-pointer "
          onClick={() => onEdit(listing.id)}
        />
      )}
    </li>
  );
}

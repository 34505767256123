import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { db } from "../firebase";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import {
  FaShare,
  FaMapMarkerAlt,
  FaBed,
  FaBath,
  FaParking,
  FaChair,
} from "react-icons/fa";




export default function Listing() {

  const params = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  
  SwiperCore.use([Autoplay, Navigation, Pagination]);
  useEffect(() => {
    async function fetchListing() {
      const docRef = doc(db, "listings", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setListing(docSnap.data());
        setLoading(false);
      }
    }
    fetchListing();
  }, [params.listingId]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <main>
 
      <div className="m-4 flex flex-col md:flex-row max-w-6xl lg:mx-auto p-4 rounded-lg shadow-lg bg-white lg:space-x-5">
        <div className=" w-full ">
        <div className="flex justify-end  items-center space-x-4 w-[98%]">
          <img
            src= {listing.imgUrls}
            alt="key"
            className="w-full rounded-2xl"
          />
        </div>

        <ul className="flex justify-end  items-center space-x-4 w-[98%]">
        <li className="text-2xl font-bold mt-3 mb-3 text-blue-900">
          
        {listing.address}
        </li>
  
          </ul>
          <ul className="flex justify-end  items-center space-x-4 w-[98%]">

            <li className="bg-green-800 w-full max-w-[200px] rounded-md p-1 text-white text-center font-semibold shadow-md">

              {listing.type === "buy" ? "شراء" : "بيع"}
            </li>

          </ul>
          <ul className="flex justify-end  items-center space-x-4 w-[98%]">

            <li className="text-2xl font-bold mt-3 mb-3 text-blue-900">
          
              {listing.name} {" "}
            </li>


          </ul>

          <div className="flex justify-end mt-3 mb-3 items-center space-x-4 w-[98%]">
            <p className="bg-green-800 w-full max-w-[1000px] rounded-md p-1 text-white text-center font-semibold shadow-md">
            {listing.description}
            </p>

          </div>

          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[80px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"وقف الخسائر"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"الهدف"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"سعر الشراء"}   
              </p>
            </div>
          </div>

          
          
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[80px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.stoploss > 1 ? `${listing.stoploss}  ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.target > 1 ? `${listing.target} ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.buyprice > 1 ? `${listing.buyprice}  ` : ""}  
              </p>
            </div>
          </div>
          <div className="flex justify-end mt-3 mb-3 items-center space-x-4 w-[98%]">
            <p className="bg-green-800 w-full max-w-[1000px] rounded-md p-1 text-white text-center font-semibold shadow-md">

            </p>

          </div>

          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[60%]">
              <p className="bg-green-600 w-full max-w-[90px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"المقاومة الثالثة"}   
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[60%]">
              <p className="bg-green-600 w-full max-w-[90px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"المقاومة الثانية"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[60%]">
              <p className="bg-green-600 w-full max-w-[90px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"المقاومة الاولي"}  
              </p>
            </div>
          </div>

          
          
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[80px] rounded-md p-1  text-center font-bold text-xs shadow-md">

              {+listing.res3 > 1 ? `${listing.res3}  ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.res2 > 1 ? `${listing.res2}  ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.res1 > 1 ? `${listing.res1}  ` : ""}          
              </p>
            </div>
          </div>

          <div className="flex justify-end mt-3 mb-3 items-center space-x-4 w-[98%]">
            <p className="bg-green-800 w-full max-w-[1000px] rounded-md p-1 text-white text-center font-semibold shadow-md">

            </p>

          </div>


          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[80px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">

              {" الدعم الثالث"}   
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {"الدعم الثاني"}  
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-green-600 w-full max-w-[70px] rounded-md p-1 text-white text-center font-bold text-xs shadow-md">
              {" الدعم الاول"}      
              </p>
            </div>
          </div>

          
          
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[80px] rounded-md p-1  text-center font-bold text-xs shadow-md">
            
              {+listing.sup3 > 1 ? `${listing.sup3}  ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.sup2 > 1 ? `${listing.sup2}   ` : ""}
              </p>
            </div>
            <div className="flex justify-end mt-1 mb-1 items-center space-x-4 w-[98%]">
              <p className="bg-white w-full max-w-[70px] rounded-md p-1  text-center font-bold text-xs shadow-md">
              {+listing.sup1> 1 ? `${listing.sup1}  ` : ""}
              </p>
            </div>
          </div>
     
        </div>
       
      </div>
    </main>
  );
}

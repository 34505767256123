import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Footer from "./components/Footer.js";

import PrivateRoute from "./components/PrivateRoute";

import Offers from "./pages/Daytrading";
import Offersa from "./pages/shortterm";
import Offersab from "./pages/Mediumterm";
import Offersc from "./pages/Daytradingc";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Listing from "./pages/Listing";
import Listinga from "./pages/Listinga";
import Category from "./pages/Category";
import Categorya from "./pages/Categorya";
function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
       
          <Route path="/Daytrading" element={<PrivateRoute />}>
            <Route path="/Daytrading" element={<Offers />} />
          </Route>
          <Route path="/Daytradingc" element={<PrivateRoute />}>
            <Route path="/Daytradingc" element={<Offersc />} />
          </Route>
          <Route path="/shortterm" element={<PrivateRoute />}>
            <Route path="/shortterm" element={<Offersa />} />
          </Route>
          <Route path="/Mediumterm" element={<PrivateRoute />}>
            <Route path="/Mediumterm" element={<Offersab />} />
          </Route>
  
  
        
          <Route path="/sign-in" element={<SignIn />} />


          <Route
            path="/category/:categoryName/:listingId"
            element={<Listing />}
          />
          
          <Route path="/category/:categoryName" element={<Category />} />

          <Route
            path="/categorya/:categoryName/:listingId"
            element={<Listinga />}
          />
          
          <Route path="/categorya/:categoryName" element={<Categorya />} />

        </Routes>
      </Router>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
       <Footer />
    </>
    
  );
}

export default App;

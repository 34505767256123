import { getAuth} from "firebase/auth";
import {

} from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";



export default function Profile() {
  const auth = getAuth();
  const navigate = useNavigate();


  const [formData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });
  const { email } = formData;
  function onLogout() {
    auth.signOut();
    navigate("/");
  }
  
 
  return (
    <>
      <section className="max-w-6xl mx-auto flex justify-center items-center flex-col">
        <h1 className="text-3xl text-center mt-6 font-bold">تفاصيل الحساب</h1>
        <div className="w-full md:w-[50%] mt-6 px-3">
          <form>


            {/* Email Input */}

            <input
              type="email"
              id="email"
              value={email}
              disabled
              className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition ease-in-out"
            />

            <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg mb-6">

              <p
                onClick={onLogout}
                className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out cursor-pointer"
              >
                Sign out
              </p>
            </div>
          </form>
 
        </div>
      </section>

    </>
  );
}

import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { db } from "../firebase";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import {
  FaShare,
  FaMapMarkerAlt,
  FaBed,
  FaBath,
  FaParking,
  FaChair,
} from "react-icons/fa";




export default function Listinga() {

  const params = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  
  SwiperCore.use([Autoplay, Navigation, Pagination]);
  useEffect(() => {
    async function fetchListing() {
      const docRef = doc(db, "listings", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setListing(docSnap.data());
        setLoading(false);
      }
    }
    fetchListing();
  }, [params.listingId]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <main>
 
      <div className="m-4 flex flex-col md:flex-row max-w-6xl lg:mx-auto p-4 rounded-lg shadow-lg bg-white lg:space-x-5">
        <div className=" w-full ">
        <div className="flex justify-end  items-center space-x-4 w-[98%]">
          <img
            src= {listing.imgUrls}
            alt="key"
            className="w-full rounded-2xl"
          />
        </div>

        <ul className="flex justify-end  items-center space-x-4 w-[98%]">
        <li className="text-2xl font-bold mt-3 mb-3 text-blue-900">
          
        {listing.address}
        </li>
  
          </ul>
          <ul className="flex justify-end  items-center space-x-4 w-[98%]">


          </ul>
          <ul className="flex justify-end  items-center space-x-4 w-[98%]">

            <li className="text-2xl font-bold mt-3 mb-3 text-blue-900">
          
              {listing.name} {" "}
            </li>


          </ul>

          <div className="flex justify-end mt-3 mb-3 items-center space-x-4 w-[98%]">
            <p className="bg-green-800 w-full max-w-[1000px] rounded-md p-1 text-white text-center font-semibold shadow-md">
            {listing.description}
            </p>

          </div>

         
     
        </div>
       
      </div>
    </main>
  );
}

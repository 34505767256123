import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
 
const Footer = () => {
    return (
        <Box>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >

تم إعداد هذا التقرير بواسطة منصة تريده وهي منصة مجانية وتم اعداد هذا التقرير  وفقا للبيانات المتاحة للجميع.  وقد بُنيت وجهة النظر في هذا التقرير وفقًا لهذه البيانات بمصداقية وأساليب علمية ودقيقة نعتقد في صحتها، وهي تقرير استرشادية غير ملزمة وان ما تنتهي اليه تلك التقارير من نتائج وتوصيات هي محض توصيات ونتائج إجتهادية تحتمل الصواب والخطأ وتحتفظ منصة تريدة بكافة حقوقها الادبية والمعنوية علي البيانات والتحليلات .ويجوز الاطلاع علي هذا التقرير ولا يجوز نسخة او توزيعه او اذاعته او إعادة صياغته أو الاشتقاق منه أو القيام باي تصرف دون الاشارة الي المنصة والحصول علي موافقة كتابية من منصة تريدة . كما تحتفظ منصة تريدة بحقها في تعديل اي مما ورد بهذا التقرير دون الاعلان عن ذلك  والمنصة  و المحللين  والقائمين عليها  غير مسؤولة او مسؤوليين عن أي قرارات استثمارية أو مضاربية يتم اتخاذها بناءً على المعلومات الواردة في هذا التقرير منصة  تريدة 
            </h1>
          
        </Box>
    );
};
export default Footer;